import SettingCard from "../../../layout/main/SettingCard";
import {CFlex, DText, Flex} from "../../../../styles/CommonStyles";
import ListLayout from "../../../layout/main/ListLayout";
import useFiatWage, {FIAT_SETTING_TYPES} from "../../../../core/hooks/main/setting/fiat/useFiatWage";
import {ModeDecoration} from "../../../../styles/main/setting";
import Text from "../../../../core/utils/Text";
import {AnimatePresence} from "framer-motion";
import SubmitModal from "../../../modals/SubmitSettingModal";
import OrderUpdate from "../common/OrderUpdate";
import OrderBox from "../common/OrderBox";
import TransactionUpdate from "./updates/TransactionUpdate";
import TransactionBox from "./details/TransactionBox";


const FiatView = ({ data }) => {


    const {
        fiat,
        editMode,
        editState,
        exitEditMode,
        enterEditMode,
        onSubmitChanges,
        onDetailChange,
        loading
    } = useFiatWage(data)


    return (
        <>
            <SubmitModal
                editMode={editMode !== 'close'}
                onSubmit={onSubmitChanges}
                onCancel={exitEditMode}
                loading={loading}
            />
            <ListLayout>
                <CFlex fw>
                    <Flex fw justify='flex-end'>
                        <ModeDecoration />
                        <DText main fontSize='s'>
                            <Text tid='mode' /> :
                        </DText>
                        <DText main style={{ margin: '0 4px' }} fontSize='s'>
                            <Text tid={`${editMode}-mode`} />
                        </DText>
                    </Flex>
                    <Flex fw wrap justify='space-between'>
                        <SettingCard
                            width={'48%'}
                            title='order'
                            onEditClicked={() => enterEditMode(FIAT_SETTING_TYPES.ORDER)}
                            coin
                        >
                            <AnimatePresence exitBeforeEnter>
                                {editState[FIAT_SETTING_TYPES.ORDER] ?
                                    <OrderUpdate
                                        detail={fiat?.order}
                                        type={FIAT_SETTING_TYPES.ORDER}
                                        onDetailChange={onDetailChange}
                                        currency={'irt'}
                                    />
                                    :
                                    <OrderBox
                                        detail={fiat?.order}
                                        currency={'irt'}
                                    />
                                }
                            </AnimatePresence>
                        </SettingCard>
                        <SettingCard
                            width={'48%'}
                            title='otc'
                            onEditClicked={() => enterEditMode(FIAT_SETTING_TYPES.OTC)}
                            coin
                        >
                            <AnimatePresence exitBeforeEnter>
                                {editState[FIAT_SETTING_TYPES.OTC] ?
                                    <OrderUpdate
                                        detail={fiat?.otc}
                                        type={FIAT_SETTING_TYPES.OTC}
                                        onDetailChange={onDetailChange}
                                        currency={'irt'}
                                    />
                                    :
                                    <OrderBox
                                        detail={fiat?.otc}
                                        currency={'irt'}
                                    />
                                }
                            </AnimatePresence>
                        </SettingCard>
                    </Flex>
                    <Flex fw wrap justify='space-between'>
                        <SettingCard
                            width={'48%'}
                            title='deposit'
                            onEditClicked={() => enterEditMode(FIAT_SETTING_TYPES.DEPOSIT)}
                            coin
                        >
                            <AnimatePresence exitBeforeEnter>
                                {editState[FIAT_SETTING_TYPES.DEPOSIT] ?
                                    <TransactionUpdate
                                        detail={fiat?.deposit}
                                        type={FIAT_SETTING_TYPES.DEPOSIT}
                                        onDetailChange={onDetailChange}
                                    />
                                    :
                                    <TransactionBox
                                        detail={fiat?.deposit}
                                        type={FIAT_SETTING_TYPES.DEPOSIT}
                                    />
                                }
                            </AnimatePresence>
                        </SettingCard>
                        <SettingCard
                            width={'48%'}
                            title='withdraw'
                            onEditClicked={() => enterEditMode(FIAT_SETTING_TYPES.WITHDRAW)}
                            coin
                        >
                            <AnimatePresence exitBeforeEnter>
                                {editState[FIAT_SETTING_TYPES.WITHDRAW] ?
                                    <TransactionUpdate
                                        detail={fiat?.withdraw}
                                        type={FIAT_SETTING_TYPES.WITHDRAW}
                                        onDetailChange={onDetailChange}
                                    />
                                    :
                                    <TransactionBox
                                        detail={fiat?.withdraw}
                                        type={FIAT_SETTING_TYPES.WITHDRAW}
                                    />
                                }
                            </AnimatePresence>
                        </SettingCard>
                    </Flex>
                </CFlex>

            </ListLayout>
        </>

    )
}


export default FiatView
