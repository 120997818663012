import {useLocation, useNavigate, useParams} from "react-router-dom"
import { SOCKET_URL } from "../../../../core/constants/urls"
import {formatName, formatNumber, getNames} from "../../../../core/utils/common"
import Text from "../../../../core/utils/Text"
import { getTradeTypeColor } from "../../../../core/utils/theme"
import { Column, DText, Flex, Row } from "../../../../styles/CommonStyles"
import { TradeTypeBadge } from "../../../../styles/main/orders"
import Avatar from "../../../common/utils/Avatar"
import DateTime from "../../../common/utils/DateTime"
import Operation from "../../../common/utils/Operation"
import UserLink from "../../../common/utils/UserLink"
import TableLayout from "../../../layout/main/TableLayout"
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import {traceAttributes} from "../../../../core/constants/headers";



const TraceTable = ({data, fromUser = false}) => {

    const params = useParams()
    const { data: traces } = data
    const { cs, headers } = traceAttributes
    const location = useLocation()
    const navigate = useNavigate()
    const onDetailsClicked = (trace) => {
        if (fromUser)
            navigate(`/users/${params.id}/${params.tab}/${trace?._id}`)
        else {
            const path = location.pathname
            navigate(`${path}/${trace._id}`)
        }
    }

    const { getTableIndex } = useGetTableIndex()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess
        >
            {traces?.data?.map((trace, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    trace.user?.firstName,
                    trace.user?.lastName
                )

                return (
                    <Row cs={cs} index={idx} key={trace._id}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column avatar>
                            <Flex width='42px'>
                                <Avatar
                                    name={NAMEAVATAR}
                                    avatar={trace.user?.avatar}
                                />
                            </Flex>
                            <UserLink
                                _id={trace.userId}
                                name={formatName(FULLNAME)}
                            />
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={
                                        trace.currency === 'irt' ?
                                        require('../../../../assets/images/tooman.png')
                                        :
                                        SOCKET_URL + `assets/icon/${trace.currency}.png`
                                    }
                                    width='32px'
                                    alt=' '
                                />
                                <DText main style={{ margin: '0 8px' }}>
                                    {trace.currency?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Column>
                        <Column>
                            {formatNumber(trace.volume)}
                        </Column>
                        <Column>
                            {formatNumber(trace.wage)}
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(trace.cause?.label)}>
                                <Text tid={trace?.cause?.label} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(trace.cause?.action)}>
                                <Text tid={trace?.cause?.action ? trace?.cause?.action : trace?.cause?.label} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(trace.type)}>
                                <Text tid={trace.type} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <DateTime dt={trace.createdAt} />
                        </Column>
                        <Column>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(trace)}
                                hasDetails
                            />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )

}



export default TraceTable
