import { useGetWages, useSetWages } from "../../../../services/react-query/setting/wages";
import { useEffect, useState } from "react";
import { deepCopy, formatObjectNumbers } from "../../../../utils/common";


const useAffiliateSetting = () => {

    const { data: wages, isFetching: fetchingWages } = useGetWages()
    const { mutate: updateWages, isLoading: updatingWages, isSuccess: wagesUpdated } = useSetWages()

    const [detailsChanged, setDetailsChanged] = useState(false)
    const [affiliateData, setAffiliateData] = useState(null)

    useEffect(() => {
        wages && setAffiliateData(deepCopy(wages.affiliate))
    }, [wages])

    const checkChanges = () => {
        const cache = wages?.affiliate
        const changed =
            cache?.p2pPercent != affiliateData?.p2pPercent ||
            cache?.otcPercent != affiliateData?.otcPercent ||
            cache?.internalTransferPercent != affiliateData?.internalTransferPercent
        setDetailsChanged(changed)
    }

    const onInputValueChange = (v, type) => {
        setAffiliateData(state => ({
            ...state,
            [type]: v
        }))
    }

    useEffect(() => {
        checkChanges()
    }, [affiliateData])

    const onSubmitClicked = () => {
        const newWages = deepCopy(wages)
        newWages.affiliate = formatObjectNumbers(affiliateData)
        updateWages({ data: newWages })
    }

    return {
        affiliateData,
        onInputValueChange,
        loading: fetchingWages || updatingWages,
        detailsChanged,
        onSubmitClicked
    }
}


export default useAffiliateSetting
