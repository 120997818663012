import { Background, CFlex, CMargin, Padding } from "../../../../../styles/CommonStyles"
import MotionFade from "../../../../common/utils/MotionFade"
import Input from "../../../../common/input/Input"
import SettingRow from "../../../../common/common/SettingRow"
import CheckBox from "../../../../common/input/CheckBox"
import { AC_SETTING_TYPES } from "../../../../../core/hooks/main/setting/available-coins/useAvailableCoinsSetting"
import { useEffect } from "react"
import { AnimatePresence } from "framer-motion"
import { formatNumber } from "../../../../../core/utils/common"



const DetailUpdate = ({
    market,
    coin,
    onDetailChange
}) => {

    useEffect(() => {
        const selectedCoin = market?.find(item => item.id === coin?.id)
        if (selectedCoin) {
            onDetailChange(formatNumber(selectedCoin.buy), AC_SETTING_TYPES.DETAILS, 'buyStatic')
            onDetailChange(formatNumber(selectedCoin.sell), AC_SETTING_TYPES.DETAILS, 'sellStatic')
        }
    }, [market])

    return (
        <MotionFade>
            <CFlex fw align='flex-start'>
                <Background bg='secondaryBg'>
                    <CMargin margin='10px' />
                    <Input
                        value={coin?.fa}
                        label={'fa-name'}
                        onInputChange={(v) => onDetailChange({
                            type: AC_SETTING_TYPES.DETAILS,
                            value: v,
                            key: 'fa'
                        })}
                        width='80%'
                    />
                    <Input
                        value={coin?.name}
                        label={'en-name'}
                        onInputChange={
                            (v) => onDetailChange({
                                type: AC_SETTING_TYPES.DETAILS,
                                value: v,
                                key: 'name'
                            })
                        }
                        width='80%'
                    />
                    {/*<Input*/}
                    {/*    value={coin?.scanner}*/}
                    {/*    label={'scanner'}*/}
                    {/*    onInputChange={*/}
                    {/*        (v) => onDetailChange({*/}
                    {/*            type: AC_SETTING_TYPES.DETAILS,*/}
                    {/*            value: v,*/}
                    {/*            key: 'scanner'*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*    width='80%'*/}
                    {/*/>*/}
                    <Padding padding='0 10px'>
                        <SettingRow
                            prefix='is-active'
                            suffix={
                                <CheckBox
                                    active={coin?.isActive}
                                    onClick={
                                        (v) => onDetailChange({
                                            type: AC_SETTING_TYPES.DETAILS,
                                            value: v,
                                            key: 'isActive',
                                            isBoolean: true
                                        })
                                    }
                                />
                            }
                        />
                        <SettingRow
                            prefix='is-static'
                            suffix={
                                <CheckBox
                                    active={coin?.isStatic}
                                    onClick={
                                        (v) => onDetailChange({
                                            type: AC_SETTING_TYPES.DETAILS,
                                            value: v,
                                            key: 'isStatic',
                                            isBoolean: true
                                        })
                                    }
                                />
                            }
                        />
                    </Padding>
                    <AnimatePresence exitBeforeEnter>

                        {coin?.isStatic &&
                            <MotionFade>
                                <Background bg='secondaryBg'>
                                    <Input
                                        label='buy-static'
                                        value={coin?.buyStatic}
                                        onInputChange={v => onDetailChange({
                                            type: AC_SETTING_TYPES.DETAILS,
                                            value: v,
                                            key: 'buyStatic'
                                        })}
                                        width='80%'
                                        currency='irt'
                                        number
                                    />
                                    <Input
                                        label='sell-static'
                                        value={coin?.sellStatic}
                                        onInputChange={v => onDetailChange({
                                            type: AC_SETTING_TYPES.DETAILS,
                                            value: v,
                                            key: 'sellStatic'
                                        })}
                                        width='80%'
                                        currency='irt'
                                        number
                                    />
                                </Background>
                            </MotionFade>
                        }
                    </AnimatePresence>
                </Background>
            </CFlex>
        </MotionFade>
    )
}


export default DetailUpdate
