import {useRequestOtp, useVerify2fa, useVerifyOtp} from "../../../services/react-query/auth";
import {useEffect, useState} from "react";
import {browserName, browserVersion, osName, osVersion} from "react-device-detect";
import {validEmailRegex} from "../../../constants/common";
import {useQueryContext} from "../../../contexts/query";


const INPUT_TYPES = {
    USERNAME: 'userName',
    CODE: 'code',
    GOOGLE_AUTH: 'google_auth'
}


const useLogin = () => {

    const inputInitial = {
        username: '',
        type: null,
        google: '',
        code: ''
    }
    const [ inputData, setInputData ] = useState(inputInitial)

    const statusInitial = {
        username: null,
        google: null,
        code: null,
        captcha: null
    }
    const [ inputStatus, setInputStatus ] = useState(statusInitial)


    /**
     * Request otp
     */
    const onRequestOtpError = () => {
        setInputData(inputInitial)
        setInputStatus(statusInitial)
    }

    const onRequestOtpSuccess = () => {
        setStep(2)
        setShowTimer(true)
        setTimer(60)
    }

    const {
        isLoading: otpLoading,
        mutate: requestOtp
    } = useRequestOtp(() => {}, onRequestOtpError)

    /**
     * verify otp
     */
    const onVerifyOtpError = () => {
        //TODO:
    }

    const onVerifyOtpSuccess = () => {
        setStep(3)
    }

    const {
        data: verifyData,
        isLoading: verifyLoading,
        mutate: verifyOtp
    } = useVerifyOtp(onVerifyOtpSuccess, onVerifyOtpError)

    /**
     * verify google auth
     */
    const onVerify2faError = () => {
        //TODO:
    }

    const {
        isLoading: verify2faLoading,
        mutate: verify2fa
    } = useVerify2fa(onVerify2faError)


    const [extra, setExtra] = useState({
        deviceName: '',
        deviceVersion: '',
        osDevice: '',
        appVersion: '',
    })

    /**
     * step - 1) username, 2) code, 3) google
     */
    const [step, setStep] = useState(1)
    const [timer, setTimer] = useState()
    const [showTimer, setShowTimer] = useState(false)


    const [validAction, setValidAction] = useState(false)


    /**
     * effects - timer and action validation
     */
    useEffect(() => {
        setExtra({
            deviceName: browserName,
            deviceVersion: browserVersion,
            osDevice: `${osName} ${osVersion}`,
            appVersion: 1,
        })
    }, [])

    useEffect(() => {
        const valid =
            ((inputStatus.username && step === 1) ||
                (inputStatus.code && step === 2) ||
                (inputStatus.google && step === 3) )
            // && inputStatus.captcha

        setValidAction(valid)

    }, [inputStatus, step])

    useEffect(() => {
        let interval
        if (showTimer) {
            interval = setInterval(() => {
                setTimer((time) => {
                    if (time === 1) {
                        clearInterval(interval)
                        setShowTimer(false)
                    }
                    return time - 1
                })
            }, 1000)
        }
        return () => {
            interval && clearInterval(interval)
        }
    }, [showTimer])


    const onInputValueChange = (v, type) => {

        switch (type){
            case INPUT_TYPES.USERNAME:
                validateUsername(v)
                setInputData(state => ({...state, username: v}))
                break
            case INPUT_TYPES.CODE:
                validateCode(v)
                setInputData(state => ({...state, code: v}))
                break
            case INPUT_TYPES.GOOGLE_AUTH:
                validate2fa(v)
                setInputData(state => ({...state, google: v}))
                break
            default:
                break
        }
    }

    /**
     * validations
     */
    const validateUsername = (val) => {
        if (val && val.length > 0) {
            if (isNaN(val)) {
                // gmail
                const valid = validEmailRegex.test(val)
                setInputData(state => ({...state, type: 'email'}))
                setInputStatus(state => ({...state, username: valid}))
            } else {
                // mobile
                setInputData(state => ({...state, type: 'mobile'}))
                // eslint-disable-next-line
                if (!['۰', '0'].includes(val[0]) || val.length !== 11) {
                    setInputStatus(state => ({...state, username: false}))
                } else {
                    setInputStatus(state => ({...state, username: true}))
                }
            }
        }
        if (val.length === 0) {
            setInputData(state => ({...state, type: null}))
            setInputStatus(state => ({...state, username: null}))
        }
    }

    const validateCode = (val) => {
        const valid = val.length === 6
        setInputStatus(state => ({...state, code: valid}))
    }

    const validate2fa = (val) => {
        const valid = val.length === 6
        setInputStatus(state => ({...state, google: valid}))
    }


    /**
     * operations
     */
    const onCaptchaStatusChange = (status) => setInputStatus(state => ({...state, captcha: status}))

    const { setToast } = useQueryContext()
    const onSubmitClicked = () => {
        if (validAction) {
            switch (step) {
                case 1:
                    requestOtp({
                        [inputData.type]: inputData.username
                    })
                    onRequestOtpSuccess()
                    break
                case 2:
                    verifyOtp({
                        [inputData.type]: inputData.username,
                        code: inputData.code,
                        extra
                    })
                    break
                case 3:
                    verify2fa({
                        preToken:verifyData?.data?.data?.preToken,
                        extra,
                        code: inputData.google
                    })
                    break
                default:
                    break
            }
        }else {
            setToast({
                isError: true, show: true,
                message: 'fill-input-errors'
            })
        }
    }

    const onResendClicked = () => {
        if (timer === 0) {
            requestOtp({
                [inputData.type]: inputData.username
            })
        }
    }

    return {
        step,
        inputData,
        inputStatus,
        timer,
        loading: otpLoading || verifyLoading || verify2faLoading,
        validAction,
        onInputValueChange,
        onSubmitClicked,
        onCaptchaStatusChange,
        onResendClicked,
    }
}

export default useLogin
export {
    INPUT_TYPES
}
