import {DText, Flex} from "../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../core/constants/urls";
import styled, {css} from "styled-components";
import {useMainContext} from "../../../core/contexts/main";


const CoinMarket = (props) => {

    const { lang } = useMainContext()
    const {
        coin,
        pair,
        detail = false,
        noName = false
    } = props

    return (
        <Flex fw justify={'flex-start'} style={{ margin: detail && '0 0 0 16px' }}>
            <Flex width={detail ? '100%' : '32px'}>
                <Image
                    coin
                    detail={detail}
                    src={SOCKET_URL + `assets/icon/${coin}.png`}
                    alt={' '}
                    width={detail ? '42px' : '24px'}
                />
                {pair === 'irt' ?
                    <Image
                        src={require('../../../assets/images/tooman.png')}
                        alt={' '}
                        width={detail ? '42px' : '24px'}
                    />
                    :
                    <Image
                        src={SOCKET_URL + `assets/icon/${pair}.png`}
                        alt={' '}
                        width={detail ? '42px' : '24px'}
                    />
                }

            </Flex>
            {!noName &&
                <DText main fontSize={'ss'} margin={lang === 'en' && '0 8px'}>
                    {coin?.toUpperCase()} / {pair?.toUpperCase()}
                </DText>
            }
        </Flex>
    )
}


const Image = styled.img`
  position: absolute;
  
  ${props => !props.theme.english && css`
    left: ${props => props.coin ? '0' : '10px'};
  `};
  ${props => props.theme.english && css`
    right: ${props => props.coin ? '0' : '-10px'};
  `};
  
  margin: 0 5px;
  ${props => props.coin && css`
    bottom: -8px;
    z-index: 1;
  `};
  
  ${props => props.detail && css`
    bottom: -12px;
    left: -20px;
  `};
`

export default CoinMarket
