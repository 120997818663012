import { SOCKET_URL } from "../../../../../core/constants/urls"
import useAffiliateTrace from "../../../../../core/hooks/main/affiliates/useAffiliateTrace"
import { CMargin, Column, DText, Flex, Row } from "../../../../../styles/CommonStyles"
import NoData from "../../../../common/utils/NoData"
import Operation from "../../../../common/utils/Operation"
import Select from "../../../../common/dropdown/Select"
import TableLayout from "../../../../layout/main/TableLayout"
import AffiliateShare from "../AffiliateShare"
import {affiliateTraceAttribute} from "../../../../../core/constants/headers";



const AffiliateTraceTable = () => {

    const { cs, headers } = affiliateTraceAttribute
    const {
        hasWriteAccess,
        traces,
        tracesList,
        availableCoins,
        coin,
        onCoinChange,
        onDetailsClicked,
        loading
    } = useAffiliateTrace()


    return (
        <>
            <CMargin margin='20px' />
            <Select
                options={availableCoins}
                value={coin}
                onValueChange={onCoinChange}
                placeHolder={'search'}
                width={'280px'}
                height={'42px'}
                isCoin
            />

            <TableLayout
                headers={headers}
                cs={cs}
                data={{data: tracesList, loading}}
                hasWriteAccess={hasWriteAccess}
                hasPagination={false}
            >
                {traces?.map((trace, idx) => (
                    <Row index={idx} cs={cs} key={trace.currency}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={
                                        trace.currency === 'irt' ?
                                        require('../../../../../assets/images/tooman.png')
                                        :
                                        SOCKET_URL + `assets/icon/${trace.currency}.png`
                                    }
                                    width='32px'
                                    alt=' '
                                />
                                <DText style={{ margin: '0 8px' }}>
                                    {trace.currency?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Column>

                        <Column>
                            <AffiliateShare
                                item={trace.actor}
                            />
                        </Column>

                        <Column>
                            <AffiliateShare
                                item={trace.object}
                            />
                        </Column>

                        <Column>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(trace)}
                                hasDetails={hasWriteAccess}
                            />
                        </Column>
                    </Row>
                ))}
                {!traces?.length &&
                    <NoData />
                }
            </TableLayout>
        </>
    )
}


export default AffiliateTraceTable
