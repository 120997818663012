import { SOCKET_URL } from "../../../../../core/constants/urls"
import { useMainContext } from "../../../../../core/contexts/main"
import { formatDate, formatName, formatNumber, getNames } from "../../../../../core/utils/common"
import Text from "../../../../../core/utils/Text"
import { CFlex, CMargin, DText, Flex, Row } from "../../../../../styles/CommonStyles"
import { LineBreak } from "../../../../../styles/main/MainCommonStyles"
import Avatar from "../../../../common/utils/Avatar"
import UserLink from "../../../../common/utils/UserLink"
import RTableLayout from "../../../layout/RTableLayout"
import { TiArrowRight } from "react-icons/ti";



const RTransferTable = ({ data }) => {

    const { lang } = useMainContext()
    const { data: transfers } = data

    return (
        <RTableLayout
            data={data}
        >
            {transfers?.data?.map((item, idx) => {

                const {
                    FULLNAME: fromName,
                    NAMEAVATAR: fromAvatar
                } = getNames(
                    item.from?.firstName,
                    item.from?.lastName
                )

                const {
                    FULLNAME: toName,
                    NAMEAVATAR: toAvatar
                } = getNames(
                    item.to?.firstName,
                    item.to?.lastName
                )

                return (
                    <Row cs={'100%'} index={idx} key={item._id}>
                        <Flex justify={'space-between'}>
                            <CFlex fw>
                                <CMargin margin={'4px'} />
                                <Avatar
                                    name={fromAvatar}
                                    avatar={item.from?.avatar}
                                />
                                <CMargin margin={'6px'} />
                                <UserLink
                                    _id={item.fromId}
                                    name={formatName(fromName)}
                                />
                            </CFlex>

                            <DText main style={{ transform: lang === 'fa' ? 'rotate(180deg)' : '' }}>
                                <TiArrowRight size={24} />
                            </DText>

                            <CFlex fw>
                                <CMargin margin={'4px'} />
                                <Avatar
                                    name={toAvatar}
                                    avatar={item.to?.avatar}
                                />
                                <CMargin margin={'6px'} />
                                <UserLink
                                    _id={item.toId}
                                    name={formatName(toName)}
                                />
                            </CFlex>
                        </Flex>

                        <LineBreak />

                        <Flex fw justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'currency'} /> :
                            </DText>
                            <Flex justify={'flex-start'}>
                                <img
                                    src={
                                        item.currency === 'irt' ?
                                            require('../../../../../assets/images/tooman.png') :
                                            SOCKET_URL + `assets/icon/${item.currency}.png`
                                    }
                                    alt={' '}
                                    width={'24px'}
                                />
                                <DText main style={{ margin: '0 8px' }}>
                                    {item.currency?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Flex>

                        <Flex fw justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'amount'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                {formatNumber(item.amount)}
                            </DText>
                        </Flex>

                        <Flex fw justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'wage'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                {formatNumber(item.wage)}
                            </DText>
                        </Flex>

                        <Flex fw justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'date'} /> :
                            </DText>
                            <DText fontSize={'s'} main dir={'ltr'}>
                                {`${formatDate(item.createdAt)} - ${formatDate(item.createdAt, 'time', lang)}`}
                            </DText>
                        </Flex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}

export default RTransferTable


