import RTableLayout from "../../../layout/RTableLayout";
import {CFlex, CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import useAffiliateTrace from "../../../../../core/hooks/main/affiliates/useAffiliateTrace";
import Operation from "../../../../common/utils/Operation";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import AffiliateShare from "../../../../main/reports/affiliates/AffiliateShare";


const RAffiliateTraceTable = () => {

    const {
        hasWriteAccess,
        traces,
        tracesList,
        availableCoins,
        coin,
        onCoinChange,
        onDetailsClicked,
        loading
    } = useAffiliateTrace()

    return (
        <RTableLayout
            data={{data: tracesList, loading}}
            hasPagination={false}
        >
            {traces?.map((trace, idx) => {

                return (
                    <Row cs={'100%'} index={idx}>
                        <CFlex fw>
                            <CMargin margin={'4px'} />
                            <Flex fw justify={'space-between'}>
                                <Flex>
                                    <img
                                        src={
                                            trace.currency === 'irt' ?
                                                require('../../../../../assets/images/tooman.png')
                                                :
                                                SOCKET_URL + `assets/icon/${trace.currency}.png`
                                        }
                                        width='32px'
                                        alt=' '
                                    />
                                    <DText style={{ margin: '0 8px' }}>
                                        {trace.currency?.toUpperCase()}
                                    </DText>
                                </Flex>
                                <Operation
                                    onDetailsClicked={() => onDetailsClicked(trace)}
                                    hasDetails={hasWriteAccess}
                                />
                            </Flex>
                            <CMargin margin={'4px'} />
                            <AffiliateShare
                                item={trace.actor}
                            />
                            <CMargin margin={'4px'} />
                            <AffiliateShare
                                item={trace.object}
                            />
                        </CFlex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}


export default RAffiliateTraceTable
