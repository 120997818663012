import {CloseIcon, OpenIcon, SearchInput, SelectWrapper} from "../../../styles/components/common/select-styles";
import {DText} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import Dropdown from "./Dropdown";
import {useAvailableCoins} from "../../../core/services/react-query/setting";
import {useEffect, useState} from "react";
import CoinRow from "../common/CoinRow";
import {deepCopy} from "../../../core/utils/common";


const Select = ({
    value,
    onValueChange,
    placeHolder,
    options,
    width,
    height,
    dropdownWidth,
    direction= 'bottom',
    size= 'big',
    fontSize = 'm',
    isCoin,
    isPredefined,
    hasIrt = false,

}) => {

    const [coins, setCoins] = useState([])
    const { data: availableCoins } = useAvailableCoins()
    useEffect(() => {
        if (!!availableCoins) {
            let newCoins = deepCopy(availableCoins)
            if (hasIrt) newCoins.push({ id: 'irt', name: 'Tooman', fa: 'تومان' })
            setCoins(newCoins)
        }
    }, [availableCoins])

    const [filteredOptions, setFilteredOptions] = useState([])

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    const [search, setSearch] = useState('')
    const [_value, setValue] = useState('')

    useEffect(() => {
        if (isCoin && coins) {
            const coin = coins.find(item => item === value)
            if (coin) {
                setValue(coin)
                return
            }
        }
        setValue(value ? value: placeHolder)

    }, [isCoin, coins, value])


    const onSearchChange = (value) => {
        if (value) {
            let _value = value.toLowerCase()
            const temp = options.filter(item =>
                item.name?.toLowerCase()?.includes(_value) ||
                item.fa?.includes(_value) ||
                item.id?.includes(_value)
            )
            setFilteredOptions(temp)
        }else {
            setFilteredOptions(options)
        }

    }

    useEffect(() => {
        onSearchChange(search)
    }, [search])

    const _onValueChange = (idx) => {
        if (isCoin) {
            const coin = filteredOptions[idx]
            let realIdx
            if (isPredefined) realIdx = idx
            else realIdx = coins.findIndex(item => item.id === coin.id)

			if (realIdx < 0) realIdx = idx
            onValueChange(realIdx)
        }else {
            onValueChange(idx)
        }
    }


    const TSelect = (props) => {

        const { open } = props

        useEffect(() => {
            if (!open) setSearch('')
        }, [open])

        return (
            <SelectWrapper
                height={height}
                size={size}
                {...props}
            >
                {(isCoin && open) ?
                    <SearchInput
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value)}
                        autoFocus
                    />
                    :
                    <DText main fontSize={fontSize}>

                        {(isCoin && coins && value) ?
                            <CoinRow
                                coin={_value}
                            />
                            :
                            <Text tid={_value} />
                        }
                    </DText>
                }


                {open ?
                    <CloseIcon size={20} />
                    :
                    <OpenIcon size={20} />
                }
            </SelectWrapper>
        )
    }

    return (
        <>
            <Dropdown
                width={width}
                options={filteredOptions}
                Controller={TSelect}
                minWidth={dropdownWidth}
                bottom={direction === 'top' && '110%'}
                top={direction === 'top' && 'unset'}
                onOptionClicked={_onValueChange}
                selected={value}
                isCoin={isCoin}
            />
        </>
    )
}


export default Select
