import MotionFade from "../../../../common/utils/MotionFade";
import {DollarDetailBox} from "../../../../../styles/main/setting";
import {Background, Flex} from "../../../../../styles/CommonStyles";
import Input from "../../../../common/input/Input";
import {DOLLAR_FEE_TYPES} from "../../../../../core/hooks/main/setting/general/useDollarFee";


const DollarFeeUpdate = ({
    data,
    onInputValueChange
}) => {

    return (
        <MotionFade>
            <Flex fw wrap justify={'space-around'}>
                <DollarDetailBox type={'buy'} update>
                    <Background bg={'secondaryBg'}>
                        <Input
                            label={'buy-static'}
                            value={data?.buyStatic}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.BUY_STATIC)}
                            size={'small'}
                            currency={'irt'}
                            number
                        />
                        <Input
                            label={'buy-max-price'}
                            value={data?.buyMax}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.BUY_MAX)}
                            size={'small'}
                            currency={'irt'}
                            number
                        />
                        <Input
                            label={'buy-min-price'}
                            value={data?.buyMin}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.BUY_MIN)}
                            size={'small'}
                            currency={'irt'}
                            number
                        />
                        <Input
                            label={'buy-factor'}
                            value={data?.buyFactor}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.BUY_FACTOR)}
                            size={'small'}
                            number
                        />
                    </Background>
                </DollarDetailBox>
                <DollarDetailBox type={'sell'} update>
                    <Background bg={'secondaryBg'}>
                        <Input
                            label={'sell-static'}
                            value={data?.sellStatic}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.SELL_STATIC)}
                            size={'small'}
                            currency={'irt'}
                            number
                        />
                        <Input
                            label={'sell-max-price'}
                            value={data?.sellMax}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.SELL_MAX)}
                            size={'small'}
                            currency={'irt'}
                            number
                        />
                        <Input
                            label={'sell-min-price'}
                            value={data?.sellMin}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.SELL_MIN)}
                            size={'small'}
                            currency={'irt'}
                            number
                        />
                        <Input
                            label={'sell-factor'}
                            value={data?.sellFactor}
                            onInputChange={(v) => onInputValueChange(v, DOLLAR_FEE_TYPES.SELL_FACTOR)}
                            size={'small'}
                            number
                        />
                    </Background>
                </DollarDetailBox>
            </Flex>
        </MotionFade>
    )
}


export default DollarFeeUpdate
