import { useState } from "react"
import FilterLayout from "../../../components/layout/filter/FilterLayout"
import ListLayout from "../../../components/layout/main/ListLayout"
import TransferTable from "../../../components/main/reports/transfer/TransferTable"
import RTransferTable from "../../../components/responsive/main/reports/transfer/RTransferTable"
import { TABLET_SIZE } from "../../../core/constants/common"
import { useWindowSize } from "../../../core/hooks/common/useWindowSize"
import { useGetAllTransfers } from "../../../core/services/react-query/report/transfer"
import { PageCard } from "../../../styles/CommonStyles"
import { CacheKeys, transferOptions } from "../../../core/constants/filter"



const Transfers = () => {

    const { width } = useWindowSize()
    const Component = width > TABLET_SIZE ? TransferTable : RTransferTable

    const [transfers, setTransfers] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setTransfers(res)

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetAllTransfers}
                    onQuerySuccess={onQuerySuccess}
                    options={transferOptions}
                    cache={CacheKeys.TRANSFERS}
                // hasDownload={}
                // sortOptions={}
                >
                    {width &&
                        <Component
                            data={transfers}
                        />
                    }

                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Transfers