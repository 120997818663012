import {Background, CFlex} from "../../../styles/CommonStyles";
import Select from "../dropdown/Select";
import {useAvailableCoins} from "../../../core/services/react-query/setting";
import {useEffect, useState} from "react";
import {deepCopy} from "../../../core/utils/common";


const CoinSelect = ({ value, onChange, width, hasIrt = false }) => {

    const { data: availableCoins } = useAvailableCoins()
    const [coins, setCoins] = useState([])
    useEffect(() => {
        if (!!availableCoins) {
            const newCoins = deepCopy(availableCoins)
            if (hasIrt) newCoins.push({ id: 'irt', fa: 'تومان', name: 'Tooman' })
            setCoins(newCoins)
        }
    }, [availableCoins])

    return (
        <CFlex>
            <Background bg={'primaryBg'}>
                <Select
                    options={coins}
                    value={value}
                    onValueChange={idx => onChange(coins[idx])}
                    placeHolder={'select-coin'}
                    width={width || '200px'}
                    height={'42px'}
                    size={'small'}
                    isCoin
                    hasIrt={hasIrt}
                />
            </Background>
        </CFlex>
    )
}

export default CoinSelect
