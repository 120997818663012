import styled, {keyframes} from "styled-components";


const Shimmer = () => {


    return (
        <Wrapper>
            <Item />
        </Wrapper>
    )
}


const loading = keyframes`
    0% {transform: translateX(-150%);}
    20% {transform: translateX(60%);}
    100% {transform: translateX(150%);}
`


const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  animation: ${loading} 1.5s infinite;
  overflow: hidden;
`

const Item = styled.div`
  width: 40%;
  height: 100%;
  background-color: ${props => props.theme.shimmer};
  transform: skew(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
`



export default Shimmer
