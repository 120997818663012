import { useMutation, useQuery } from "react-query"
import { useMainContext } from "../../../../contexts/main"
import { makeQueryFilters } from "../../../../utils/query-maker"
import { filterFetch, normalFetch } from "../../../fetch-api/get"
import { putApi } from "../../../fetch-api/put"
import { useQueryContext } from "../../../../contexts/query"



export const transferKeys = {
    getAllTransfers: 'getAllTransfers',
    getTransferSettings: 'getTransferSettings',
    setTransferSettings: 'setTransferSettings'
}


export const useGetAllTransfers = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()

    const newFilters = { ...extra, ...filters }
    const params = makeQueryFilters(newFilters, lang)
    const newParams = { ...params, ...pagination }

    if (csv) {
        newParams.export = 'csv'
    }

    return useQuery(
        transferKeys.getAllTransfers, () => filterFetch(newParams, 'transfers/admin'),
        {
            cacheTime: 0
        }
    )
}


export const useGetTransferSettings = () => {

    return useQuery(
        transferKeys.getTransferSettings, () => normalFetch('settings/internal/transfer'),
        {
            select: res => res?.data?.data
        }
    )
}


export const useSetTransferSettings = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        transferKeys.setTransferSettings, (payload) => putApi(payload, 'settings/internal/transfer'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([transferKeys.getTransferSettings])
                setToast({ message: 'op-success', show: true, isError: false })
                onSuccess()
            }
        }
    )
}

