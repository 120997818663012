import styled, {css} from "styled-components";
import {CFlex, Flex} from "../../CommonStyles";


export const UserDetailsWrapper = styled.div`
  
  display: flex;
  justify-content: flex-start;
  
  @media screen and (max-width: 1050px) {
    flex-direction: column;
  };
`


export const BalanceWrapper = styled(CFlex)`
  padding: 20px 40px;
  
  @media screen and (max-width: 768px){
    padding: 0;
  };
`

export const UserDetailListWrapper = styled.div`
  width: 100%;
  padding: 10px;
`

export const UserSettingBtn = styled.div`
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}10;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.04);
  color: #ffffff;
  width: 260px;
  height: 42px;
  margin: 10px;
  filter: brightness(0.75);
  cursor: pointer;
  transition: all 0.25s;
  font-size: 0.9rem;
  
  ${props => props.active && css`
    filter: brightness(1);
    border: 2px solid ${props => props.color};
  `};

  &:hover{
    ${props => !props.active && css`
      filter: brightness(1);
      transform: translateY(-4px);
    `};
  };
  
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  };
`


export const UserActionWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.color}20;
  margin: 15px 0;
  padding: 15px 0;
`


export const UserDetailsBox = styled.div`
  border: 1px solid ${props => props.theme.color}80;
  box-shadow: 0 0 2px rgb(0, 0, 0, 0.015);
  width: 480px;
  border-radius: 4px;
  margin: 10px;
  
  @media screen and (max-width: 768px) {
    width: 300px;
  };
`

export const TextArea = styled.textarea`
  background-color: transparent;
  border: 1px solid ${props => props.theme.color}20;
  color: ${props => props.theme.color};
  font-size: 0.9rem;
  width: ${props => props.width || '100%'};
  min-height: ${props => props.minHeight || '120px'};
  padding: 10px;
  font-family: Vazir;
  
  @media screen and (max-width: 768px) {
    font-size: 0.75rem;
  };
`


export const AcceptUserRow = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  min-height: 28px;
`

export const InquiryBtn = styled.div`
  font-size: 0.8rem;
  background-color: ${props => props.theme.secondaryBg};
  width: 140px;
  height: 32px;
  border-radius: 4px;
  color: ${props => props.theme.color};
  border: 1px solid ${props => props.theme.color}40;
  box-shadow: 0 0 2px rgb(0, 0, 0, 0.05);
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  
  ${props => !props.active && css`
    filter: brightness(0.5);
  `};
`

export const UserDetailSettingBox = styled(CFlex)`
  width: 400px;
  align-items: flex-start;
  
  @media screen and (max-width: 768px) {
    width: 300px;
  };
`
