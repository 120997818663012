import {
    HeaderWrapper,
    NotificationIcon,
    MobileMenu
} from "../../../styles/layout/header";
import {Flex, IconWrapper, OnlyDesktop} from "../../../styles/CommonStyles";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE, TABLET_SIZE} from "../../../core/constants/common";
import {useEffect, useRef, useState} from "react";
import {useMainContext} from "../../../core/contexts/main";
import {BsMoonFill} from "react-icons/bs";
import {RiSunFill} from "react-icons/ri"
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import Select from "../../common/dropdown/Select";
import ConnectionStatus from "./ConnectionStatus";



const MainHeader = () => {

    const {width} = useWindowSize()
    const { theme, setTheme, lang, setLang, onSidebarChange } = useMainContext()

    /**
     * states
     */
    const [language, setLanguage] = useState(lang === 'en' ? 'English' : 'فارسی')
    const [notificationOpen, setNotificationsOpen] = useState(false)

    const outRef = useRef()
    const onOutsideClicked = () => {
        setNotificationsOpen(false)
    }
    useClickOutside(outRef, onOutsideClicked)

    const onThemeChange = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }

    const onLangChange = (idx) => {
        setLanguage(languages[idx])
        setLang(
            languages[idx] === 'English' ? 'en' : 'fa'
        )
    }


    return (
        <HeaderWrapper resp={width < MOBILE_SIZE}>
            <Flex fh>
                <img
                    src={require("../../../assets/images/exch.png")}
                    width={width < MOBILE_SIZE ? "90px" : "130px"}
                    alt=" "
                />
            </Flex>

            <Flex fh>
                <OnlyDesktop>
                    <Flex style={{ margin: '0 20px' }}>
                        <Select
                            options={languages}
                            value={language}
                            onValueChange={onLangChange}
                            height='42px'
                            width={width < MOBILE_SIZE ? '64px' : '120px'}
                            selected={language}
                        />
                    </Flex>
                </OnlyDesktop>

                {/*<IconWrapper>*/}
                {/*    <NotificationIcon*/}
                {/*        onClick={() => setNotificationsOpen(true)}*/}
                {/*        size={width < TABLET_SIZE ? 20 : 25}*/}
                {/*    />*/}
                {/*</IconWrapper>*/}

                <OnlyDesktop>
                    <Flex onClick={onThemeChange} style={{ margin: '0 15px', cursor: 'pointer' }}>
                        {theme === 'dark' ? (
                            <IconWrapper>
                                <RiSunFill color='#ffc800' size={24} />
                            </IconWrapper>
                        ) : (
                            <IconWrapper>
                                <BsMoonFill color='#c3c5b7' size={24} />
                            </IconWrapper>
                        )}
                    </Flex>
                </OnlyDesktop>

                <ConnectionStatus />

                { width < MOBILE_SIZE &&
                    <MobileMenu
                        size={24}
                        onClick={onSidebarChange}
                    />
                }
            </Flex>
        </HeaderWrapper>
    )
}



const languages = [
    'English',
    'فارسی'
]

export default MainHeader
