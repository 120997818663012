import { useState } from "react"
import { TABLET_SIZE } from "../../../../../core/constants/common"
import { affiliateListFilterOptions, affiliateListSortOptions, CacheKeys } from "../../../../../core/constants/filter"
import { useWindowSize } from "../../../../../core/hooks/common/useWindowSize"
import { useGetAffiliateList } from "../../../../../core/services/react-query/report/trace"
import FilterLayout from "../../../../layout/filter/FilterLayout"
import RAffiliateListTable from "../../../../responsive/main/reports/affiliates/RAffiliateListTable"
import AffiliateListTable from "./AffiliateListTable"
import { useAclContext } from "../../../../../core/contexts/acl";



const AffiliateList = () => {


    const { width } = useWindowSize()
    const [affiliates, setAffiliates] = useState({ data: null, loading: true })

    const onQuerySuccess = (res) => {
        setAffiliates(res)
    }

    const Component = width > TABLET_SIZE ? AffiliateListTable : RAffiliateListTable

    return (
        <FilterLayout
            query={useGetAffiliateList}
            cache={CacheKeys.AFFILIATE_LIST}
            onQuerySuccess={onQuerySuccess}
            options={affiliateListFilterOptions}
            sortOptions={affiliateListSortOptions}
            extra={{ isDelete: false }}
        >
            {width &&
                <Component data={affiliates} />
            }
        </FilterLayout>
    )
}


export default AffiliateList
