import MotionFade from "../../../../common/utils/MotionFade";
import {CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import BoundBox from "../../available-coins/details/BoundBox";
import SettingRow from "../../../../common/common/SettingRow";
import {formatNumber} from "../../../../../core/utils/common";

/**
 * @param detail - { min, max, feeFactor, feeMax }
 * @param type - deposit, withdraw
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionBox = ({
    detail,
    type
}) => {


    return (
        <MotionFade>
            {detail &&
                <>
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid={`${type}-min-max-note`} />
                        </DText>
                    </Flex>
                    <BoundBox
                        min={detail.min}
                        max={detail.max}
                        currency={'irt'}
                    />
                    <CMargin margin='8px' />
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-note' />
                        </DText>
                    </Flex>
                    <CMargin margin='8px' />
                    <SettingRow
                        prefix={`${type}-fee-factor`}
                        suffix={`${detail.feeFactor} %`}
                    />
                    <CMargin margin='8px' />
                    <SettingRow
                        prefix={`${type}-fee-max`}
                        suffix={formatNumber(detail.feeMax)}
                        coin={'irt'}
                        priceNumber
                    />
                </>
            }
        </MotionFade>
    )
}


export default TransactionBox
