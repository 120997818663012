import MotionFade from "../../../common/utils/MotionFade";
import {Absolute, CFlex, CMargin, DeleteIcon, DText, Flex, IconWrapper} from "../../../../styles/CommonStyles";
import {UserLevelAddIcon, UserLevelCard} from "../../../../styles/main/setting";
import UserStars from "./UserStars";
import Text from "../../../../core/utils/Text";
import {LineBreak} from "../../../../styles/main/MainCommonStyles";
import Input from "../../../common/input/Input";


const UserLevelsUpdate = ({
    userLevels,
    onInputValueChange,
    addNewLevel,
    deleteUserLevel
}) => {

    return (
        <MotionFade>
            <Flex fw wrap align={'stretch'}>
                {userLevels?.map((level, idx) => (
                    <UserLevelCard>
                        <CFlex fw>
                            <Absolute>
                                <Flex fw justify={'flex-end'}>
                                    <IconWrapper onClick={() => deleteUserLevel(idx)}>
                                        <DeleteIcon size={22} />
                                    </IconWrapper>
                                </Flex>
                            </Absolute>
                            <UserStars count={idx + 1}/>
                            <Flex>
                                <DText main style={{ margin: '0 5px' }}>
                                    <Text tid={'user-star'} />
                                </DText>
                                <DText main>
                                    {idx + 1}
                                </DText>
                            </Flex>
                        </CFlex>
                        <LineBreak />
                        <CMargin margin={'10px'} />
                        <Input
                            value={level.min}
                            label={'user-level-min-edit'}
                            onInputChange={(v) => onInputValueChange(v, idx, 'min')}
                            number
                            currency={'irt'}
                        />
                        <CMargin margin={'5px'} />
                        <Input
                            value={level.max}
                            label={'user-level-max-edit'}
                            onInputChange={(v) => onInputValueChange(v, idx, 'max')}
                            number
                            currency={'irt'}
                        />
                        <CMargin margin={'5px'} />
                        <Input
                            value={level.off}
                            label={'user-level-off'}
                            onInputChange={(v) => onInputValueChange(v, idx, 'off')}
                            number
                        />
                    </UserLevelCard>
                ))}
                <UserLevelCard add>
                    <Flex fw fh>
                        <IconWrapper onClick={addNewLevel}>
                            <UserLevelAddIcon size={32}/>
                            <DText main style={{ margin: '0 5px' }}>
                                <Text tid={'add-user-level'} />
                            </DText>
                        </IconWrapper>
                    </Flex>
                </UserLevelCard>
            </Flex>
        </MotionFade>
    )
}


export default UserLevelsUpdate
