import MotionFade from "../../../../common/utils/MotionFade";
import {Flex} from "../../../../../styles/CommonStyles";
import {DollarDetailBox} from "../../../../../styles/main/setting";
import SettingRow from "../../../../common/common/SettingRow";


const DollarFeeDetails = ({
    data
}) => {

    return (
        <MotionFade>
            <Flex fw wrap justify={'space-around'}>
                <DollarDetailBox type={'buy'}>
                    <SettingRow
                        prefix={'buy-static'}
                        suffix={data?.buyStatic}
                        priceNumber
                        size={'small'}
                        coin={'irt'}
                    />
                    <SettingRow
                        prefix={'buy-max-price'}
                        suffix={data?.buyMax}
                        priceNumber
                        size={'small'}
                        coin={'irt'}
                    />
                    <SettingRow
                        prefix={'buy-min-price'}
                        suffix={data?.buyMin}
                        priceNumber
                        size={'small'}
                        coin={'irt'}
                    />
                    <SettingRow
                        prefix={'buy-factor'}
                        suffix={`${data?.buyFactor} %`}
                        size={'small'}
                    />
                </DollarDetailBox>
                <DollarDetailBox type={'sell'}>
                    <SettingRow
                        prefix={'sell-static'}
                        suffix={data?.sellStatic}
                        priceNumber
                        size={'small'}
                        coin={'irt'}
                    />
                    <SettingRow
                        prefix={'sell-max-price'}
                        suffix={data?.sellMax}
                        priceNumber
                        size={'small'}
                        coin={'irt'}
                    />
                    <SettingRow
                        prefix={'sell-min-price'}
                        suffix={data?.sellMin}
                        priceNumber
                        size={'small'}
                        coin={'irt'}
                    />
                    <SettingRow
                        prefix={'sell-factor'}
                        suffix={`${data?.sellFactor} %`}
                        size={'small'}
                    />
                </DollarDetailBox>
            </Flex>
        </MotionFade>
    )
}


export default DollarFeeDetails
