import { CMargin, DText, Flex, Row } from '../../../../styles/CommonStyles'
import { SOCKET_URL } from '../../../../core/constants/urls'
import Text from '../../../../core/utils/Text'
import { formatNumber } from '../../../../core/utils/common'
import { MiniLineBreak, RespIndex } from '../../../../styles/main/MainCommonStyles'
import RTableLayout from '../../layout/RTableLayout'
import { useFullNode } from '../../../../core/hooks/useFullNode'
import { TradeTypeBadge } from '../../../../styles/main/orders'
import {Link} from "react-router-dom";

const RFullnodeStatsTable = ({ data }) => {
	const { data: stats } = data

	const { CUMULATIVE_STATUSES, getStatusInfo, mergeSymbols } = useFullNode()

	const mergedSymbols = mergeSymbols(stats?.data)

	return (
		<RTableLayout data={data} hasPagination={false}>
			{mergedSymbols?.map((stat, idx) => (
				<Row idx={idx} cs={'100%'} key={stat.symbol}>
					<Flex justify='space-between'>
						<Flex>
							<img
								src={
									stat.symbol === 'irt'
										? require('../../../../assets/images/tooman.png')
										: SOCKET_URL + `assets/icon/${stat.symbol}.png`
								}
								width='32px'
								alt=' '
							/>
							<DText style={{ margin: '0 8px' }}>{stat.symbol?.toUpperCase()}</DText>
						</Flex>
					</Flex>
					<CMargin margin='10px' />
					<Flex justify='space-between'>
						<DText main fontSize='s'>
							<Text tid='amount' /> :
						</DText>
						<div>
							{stat?.status?.length && (
								<div style={{ display: 'flex', flexDirection: 'column', gap: '0.375rem' }}>
									{stat.status.map((item) => {
										const statusInfo = getStatusInfo(item.cumulativeStatus)

										return (
											<div key={item.cumulativeStatus}>
												<Link to={`/fullnode?tab=2&symbol=${stat.symbol}&status=${item.cumulativeStatus}`}>
													<TradeTypeBadge color={statusInfo?.color} style={{ margin: '0 8px' }}>
														{item.cumulativeStatus}: {formatNumber(item.amount)}{' '}
														{stat.symbol?.toUpperCase()}
													</TradeTypeBadge>
												</Link>
											</div>
										)
									})}
								</div>
							)}
						</div>
					</Flex>
					<MiniLineBreak />
					<Flex justify='space-between'>
						<DText main fontSize='s'>
							<Text tid='count' /> :
						</DText>
						<div>
							{stat?.status?.length && (
								<div style={{ display: 'flex', flexDirection: 'column', gap: '0.375rem' }}>
									{stat.status.map((item) => {
										const statusInfo = getStatusInfo(item.cumulativeStatus)

										return (
											<div key={item.cumulativeStatus}>
												<Link to={`/fullnode?tab=2&symbol=${stat.symbol}&status=${item.cumulativeStatus}`}>
													<TradeTypeBadge color={statusInfo?.color} style={{ margin: '0 8px' }}>
														{item.cumulativeStatus}: {formatNumber(item.count)}{' '}
													</TradeTypeBadge>
												</Link>
											</div>
										)
									})}
								</div>
							)}
						</div>
					</Flex>
					<RespIndex>{idx + 1}</RespIndex>
				</Row>
			))}
		</RTableLayout>
	)
}

export default RFullnodeStatsTable
