import { CFlex, CMargin, DText, Flex } from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import { formatNumber } from "../../../../../core/utils/common";
import { MiniLineBreak } from "../../../../../styles/main/MainCommonStyles";
import { RobotSettingCard } from "../../../../../styles/main/setting";


const RobotOrderBox = ({
    market,
    type,
    transaction
}) => {

    const HAS_MIN_PRICE_FACTOR = type === 'order' || (type === 'trade' && transaction === 'sell')
    const HAS_MAX_PRICE_FACTOR = type === 'order' || (type === 'trade' && transaction === 'buy')
    const HAS_SKIP_ON_MINUTE = type === 'trade'
    const HAS_DELETE_ON_MINUTE = type === 'order'

    return (
        <RobotSettingCard>
            <CFlex fw>
                <DText color={transaction === 'buy' ? '#37b88b' : '#f6465d'}>
                    <Text tid={transaction} />
                </DText>
                <CMargin margin={'6px'} />

                {/* mutual */}
                <Flex fw justify={'space-between'}>
                    <DText main>
                        <Text tid={'min-amount'} /> ({market.coin ? market.coin.toUpperCase() : 'COIN'}):
                    </DText>
                    <DText primary>
                        {formatNumber(market[type][transaction].minAmount)}
                    </DText>
                </Flex>
                <MiniLineBreak />
                <Flex fw justify={'space-between'}>
                    <DText main>
                        <Text tid={'max-amount'} /> ({market.coin ? market.coin.toUpperCase() : 'COIN'}):
                    </DText>
                    <DText primary>
                        {formatNumber(market[type][transaction].maxAmount)}
                    </DText>
                </Flex>

                <MiniLineBreak />

                {HAS_MIN_PRICE_FACTOR &&
                    <>
                        <Flex fw justify={'space-between'}>
                            <DText main>
                                <Text tid={'minLowerPriceFactor'} /> :
                            </DText>
                            <DText primary dir={'ltr'}>
                                {formatNumber(market[type][transaction].minLowerPriceFactor)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                        <Flex fw justify={'space-between'}>
                            <DText main>
                                <Text tid={'minUpperPriceFactor'} /> :
                            </DText>
                            <DText primary dir={'ltr'}>
                                {formatNumber(market[type][transaction].minUpperPriceFactor)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                    </>
                }

                {HAS_MAX_PRICE_FACTOR &&
                    <>
                        <Flex fw justify={'space-between'}>
                            <DText main>
                                <Text tid={'maxLowerPriceFactor'} /> :
                            </DText>
                            <DText primary dir={'ltr'}>
                                {formatNumber(market[type][transaction].maxLowerPriceFactor)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                        <Flex fw justify={'space-between'}>
                            <DText main>
                                <Text tid={'maxUpperPriceFactor'} /> :
                            </DText>
                            <DText primary dir={'ltr'}>
                                {formatNumber(market[type][transaction].maxUpperPriceFactor)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                    </>
                }

                {HAS_SKIP_ON_MINUTE &&
                    <>
                        <Flex fw justify={'space-between'}>
                            <DText main>
                                <Text tid={'skip-on-minutes'} /> :
                            </DText>
                            <DText primary>
                                {market[type][transaction].skipOnMinutes}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                    </>
                }

                {HAS_DELETE_ON_MINUTE &&
                    <>
                        <Flex fw justify={'space-between'}>
                            <DText main>
                                <Text tid={'delete-on-minute'} />
                            </DText>
                            <DText primary>
                                {market[type][transaction].deleteOnMinute}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                    </>
                }

                <Flex fw justify={'space-between'}>
                    <DText main>
                        <Text tid={'skip-on-count'} /> :
                    </DText>
                    <DText primary>
                        {market[type][transaction].skipOnCount}
                    </DText>
                </Flex>
            </CFlex>
        </RobotSettingCard>
    )
}

export default RobotOrderBox
