import RTableLayout from "../../../layout/RTableLayout";
import useOtcTrades from "../../../../../core/hooks/main/orders/useOtcTrades";
import {formatName, formatNumber, getNames} from "../../../../../core/utils/common";
import {CFlex, CMargin, DText, Flex, Padding, Row} from "../../../../../styles/CommonStyles";
import CoinMarket from "../../../../common/common/CoinMarket";
import Operation from "../../../../common/utils/Operation";
import Avatar from "../../../../common/utils/Avatar";
import {BuyIcon, SellIcon, TradeAmount, TradeTypeBadge} from "../../../../../styles/main/orders";
import Text from "../../../../../core/utils/Text";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import UserLink from "../../../../common/utils/UserLink";
import {RespIndex} from "../../../../../styles/main/MainCommonStyles";


const ROtcTradesTable = ({
    data
}) => {

    const {data: trades} = data

    // no write access needed - no actions
    const {
        hasWriteAccess,
        onDetailsClicked
    } = useOtcTrades()

    return (
        <RTableLayout
            data={data}
        >
            {trades?.data?.map((trade, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    trade.user?.firstName,
                    trade.user?.lastName
                )


                return(
                    <Row cs={'100%'} index={idx} key={trade._id}>
                        <Flex fw justify={'space-evenly'}>
                            <Padding padding={'8px 12px'}>
                                <CoinMarket coin={trade.coin} pair={'irt'} />
                            </Padding>
                            <Flex justify={'flex-end'}>
                                <Operation
                                    onDetailsClicked={() => onDetailsClicked(trade)}
                                    hasWriteAccess={hasWriteAccess}
                                    hasDetails
                                />
                            </Flex>
                        </Flex>
                        <Flex fw justify={'space-evenly'}>
                            <CFlex width={'33%'}>
                                <Avatar
                                    avatar={trade.user?.avatar}
                                    name={NAMEAVATAR}
                                />
                                <CMargin margin={'6px'} />
                                <UserLink
                                    _id={trade.client?.userId}
                                    name={formatName(FULLNAME)}
                                />
                            </CFlex>

                            <CFlex width={'33%'}>
                                {trade.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                                <Flex style={{ margin: '0 7px' }}>
                                    <TradeAmount type={trade.type}>
                                        <Text tid={trade.type} />
                                    </TradeAmount>
                                </Flex>
                                <CMargin margin={'6px'} />
                                <TradeTypeBadge color={getTradeTypeColor(trade.order?.submit)}>
                                    OTC
                                </TradeTypeBadge>
                            </CFlex>
                        </Flex>
                        <CMargin margin={'8px'} />
                        <Flex fw justify='space-between'>
                            <DText fontSize={'s'} main>
                                <Text tid={'amount'} /> :
                            </DText>
                            <TradeAmount type={trade.order?.type}>
                                {trade.amount}
                            </TradeAmount>
                        </Flex>
                        <Flex fw justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'wage'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                {formatNumber(trade.wage)}
                            </DText>
                        </Flex>
                        <Flex fw justify='space-between'>
                            <DText fontSize={'s'} main>
                                <Text tid={'unitPrice'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                { formatNumber(trade.priceUnit) }
                            </DText>
                        </Flex>

                        <Flex fw justify='space-between'>
                            <DText fontSize={'s'} main>
                                <Text tid={'price'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                { formatNumber(trade.price) }
                            </DText>
                        </Flex>

                        <RespIndex>
                            {idx + 1}
                        </RespIndex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}


export default ROtcTradesTable
