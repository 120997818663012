import { AnimatePresence } from "framer-motion"
import useAvailableCoinsSetting, { AC_SETTING_TYPES } from "../../../../core/hooks/main/setting/available-coins/useAvailableCoinsSetting"
import { useMarket } from "../../../../core/services/react-query/setting"
import Text from "../../../../core/utils/Text"
import { CFlex, DeleteIcon, DText, Flex, IconWrapper } from "../../../../styles/CommonStyles"
import { AddIcon, EditIcon, ModeDecoration } from "../../../../styles/main/setting"
import Select from "../../../common/dropdown/Select"
import Tooltip from "../../../common/utils/Tooltip"
import ListLayout from "../../../layout/main/ListLayout"
import ModalLayout from "../../../layout/main/ModalLayout"
import SettingCard from "../../../layout/main/SettingCard"
import BasicModal from "../../../modals/BasicModal"
import SubmitModal from "../../../modals/SubmitSettingModal"
import DetailBox from "./details/DetailBox"
import NetworkBox from "./details/NetworkBox"
import OrderBox from "../common/OrderBox"
import PriceBox from "./details/PriceBox"
import DetailUpdate from "./updates/DetailUpdate"
import NetworkUpdate from "./updates/NetworkUpdate"
import OrderUpdate from "../common/OrderUpdate"
import PriceUpdate from "./updates/PriceUpdate"



const AvailableCoinsDetail = ({
    data,
    predefinedCoins,
    tokens,
    coinState
}) => {

    const { data: availableCoins } = data
    const { data: market } = useMarket()

    const {
        editState,
        coin,
        onCoinChange,
        editMode,
        enterEditMode,
        exitEditMode,
        onDetailChange,
        onAddNetwork,
        onDeleteNetwork,
        onSubmitChanges,
        loading,
        predefined,
        onDeleteAvailableCoin,
        deleteModal,
        onDeleteModalClose,
        onDeleteModalOpen
    } = useAvailableCoinsSetting(availableCoins, predefinedCoins, tokens, coinState)

    return (
        <>
            <ListLayout>
                <CFlex fw>
                    <Flex fw justify='flex-end'>
                        <ModeDecoration />
                        <DText main fontSize='s'>
                            <Text tid='mode' /> :
                        </DText>
                        <DText main style={{ margin: '0 4px' }} fontSize='s'>
                            <Text tid={`${editMode}-mode`} />
                        </DText>
                    </Flex>
                    <SettingCard
                        minHeight='0'
                        noTitle
                        main
                    >
                        <Flex fw justify='space-between'>
                            <Select
                                options={editMode === 'add' ? predefined : availableCoins}
                                value={coin}
                                onValueChange={onCoinChange}
                                placeHolder={'select-coin'}
                                width={'240px'}
                                height={'42px'}
                                isCoin
                                isPredefined={editMode === 'add'}
                            />
                            <Flex>
                                <Tooltip
                                    content='add-coin'
                                >
                                    <IconWrapper
                                        onClick={() => enterEditMode('add')}
                                    >
                                        <AddIcon size={24} />
                                    </IconWrapper>
                                </Tooltip>
                                <Tooltip
                                    content='edit'
                                >
                                    <IconWrapper
                                        onClick={() => enterEditMode(AC_SETTING_TYPES.ALL)}
                                    >
                                        <EditIcon size={24} />
                                    </IconWrapper>
                                </Tooltip>
                                {coin &&
                                    <Tooltip
                                        content='delete'
                                    >
                                        <IconWrapper
                                            onClick={onDeleteModalOpen}
                                        >
                                            <DeleteIcon size={24} />
                                        </IconWrapper>
                                    </Tooltip>
                                }

                            </Flex>
                        </Flex>
                    </SettingCard>

                    <Flex fw wrap justify='space-between' align={'stretch'}>

                        {/* details */}
                        <SettingCard
                            width='48%'
                            title='details'
                            coin={coin}
                            onEditClicked={() => enterEditMode(AC_SETTING_TYPES.DETAILS)}
                        >
                            <AnimatePresence exitBeforeEnter>
                                {editState[AC_SETTING_TYPES.DETAILS] ?
                                    <DetailUpdate
                                        market={market}
                                        coin={coin}
                                        onDetailChange={onDetailChange}
                                    />
                                    :
                                    <DetailBox
                                        coin={coin}
                                    />
                                }
                            </AnimatePresence>
                        </SettingCard>

                        {/* price */}
                        <SettingCard
                            width='48%'
                            title='price'
                            coin={coin}
                            onEditClicked={() => enterEditMode(AC_SETTING_TYPES.PRICE)}
                        >
                            <AnimatePresence exitBeforeEnter>
                                {editState[AC_SETTING_TYPES.PRICE] ?
                                    <PriceUpdate
                                        coin={coin}
                                        onDetailChange={onDetailChange}
                                    />
                                    :
                                    <PriceBox
                                        coin={coin}
                                        market={market}
                                    />
                                }
                            </AnimatePresence>

                        </SettingCard>
                    </Flex>

                    <Flex fw wrap justify='space-between'>
                        {/* order */}
                        <SettingCard
                            width='48%'
                            title='order'
                            coin={coin}
                            onEditClicked={() => enterEditMode(AC_SETTING_TYPES.ORDER)}
                        >
                            <AnimatePresence exitBeforeEnter>
                                {editState[AC_SETTING_TYPES.ORDER] ?
                                    <OrderUpdate
                                        detail={coin?.order}
                                        type='order'
                                        onDetailChange={onDetailChange}
                                        currency={coin?.id}
                                    />
                                    :
                                    <OrderBox
                                        detail={coin?.order}
                                        currency={coin?.id}
                                    />
                                }
                            </AnimatePresence>
                        </SettingCard>

                        {/* otc */}
                        <SettingCard
                            width='48%'
                            title='otc'
                            coin={coin}
                            onEditClicked={() => enterEditMode(AC_SETTING_TYPES.OTC)}
                        >
                            {editState[AC_SETTING_TYPES.OTC] ?
                                <OrderUpdate
                                    detail={coin?.otc}
                                    type='otc'
                                    onDetailChange={onDetailChange}
                                    currency={coin?.id}
                                />
                                :
                                <OrderBox
                                    detail={coin?.otc}
                                    currency={coin?.id}
                                />
                            }
                        </SettingCard>
                    </Flex>

                    {/* withdraw list - deposit list */}

                    <SettingCard
                        title='deposit-withdraw-fee'
                        coin={coin}
                        onEditClicked={() => enterEditMode(AC_SETTING_TYPES.DEPOSIT_WITHDRAW)}
                    >
                        {editState[AC_SETTING_TYPES.DEPOSIT_WITHDRAW] ?
                            <NetworkUpdate
                                coin={coin}
                                onDetailChange={onDetailChange}
                                onAddNetwork={onAddNetwork}
                                onDeleteNetwork={onDeleteNetwork}
                            />
                            :
                            <NetworkBox
                                coin={coin}
                            />
                        }
                    </SettingCard>
                </CFlex>

                <ModalLayout
                    onClose={onDeleteModalClose}
                    open={deleteModal}
                    width={'520px'}
                >
                    <BasicModal
                        onClose={onDeleteModalClose}
                        _id={deleteModal.token?._id}
                        loading={loading}
                        head={'delete-available-coin-head'}
                        desc={'delete-available-coin-desc'}
                        onSubmit={onDeleteAvailableCoin}
                    />
                </ModalLayout>

            </ListLayout>
            <SubmitModal
                editMode={editMode !== 'close'}
                onSubmit={onSubmitChanges}
                onCancel={exitEditMode}
                loading={loading}
            />

        </>

    )
}


export default AvailableCoinsDetail
