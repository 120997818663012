import { SOCKET_URL } from "../../../../core/constants/urls"
import useWagesList from "../../../../core/hooks/main/wages-list/useWagesList"
import { formatNumber } from "../../../../core/utils/common"
import { Column, DText, Flex, Row } from "../../../../styles/CommonStyles"
import Operation from "../../../common/utils/Operation"
import Select from "../../../common/dropdown/Select"
import TableLayout from "../../../layout/main/TableLayout"
import {wagesAttributes} from "../../../../core/constants/headers";



const WagesTable = () => {

    //TODO: handy sort
    const { cs, headers } = wagesAttributes
    const {
        wages,
        wagesList,
        loading,
        availableCoins,
        coin,
        onCoinChange,
        onDetailsClicked
    } = useWagesList()

    return (
        <>
            <Select
                options={availableCoins}
                value={coin}
                onValueChange={onCoinChange}
                placeHolder={'search'}
                width={'280px'}
                height={'42px'}
                isCoin
            />
            <TableLayout
                headers={headers}
                cs={cs}
                data={{data: wagesList, loading}}
                hasPagination={false}
                hasWriteAccess
            >
                {wages?.map((wage, idx) => (

                    <Row cs={cs} index={idx} key={wage.currency}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={
                                        wage.currency === 'irt' ?
                                        require('../../../../assets/images/tooman.png')
                                        :
                                        SOCKET_URL + `assets/icon/${wage.currency}.png`
                                    }
                                    width='32px'
                                    alt=' '
                                />
                                <DText style={{ margin: '0 8px' }}>
                                    {wage.currency?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Column>
                        <Column>
                            {formatNumber(wage.count)}
                        </Column>
                        <Column style={{ lineBreak: 'anywhere' }}>
                            {formatNumber(wage.volumes)}
                        </Column>
                        <Column>
                            {formatNumber(wage.wages)}
                        </Column>
                        <Column>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(wage)}
                                hasDetails
                            />
                        </Column>
                    </Row>
                ))}
            </TableLayout>
        </>

    )
}

export default WagesTable
