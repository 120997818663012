import {PulseLoader} from "react-spinners";


const Loader = ({size}) => {

    return (
        <PulseLoader
            size={size ? size : 12}
            color={'#ffc800'}
        />
    )
}

export default Loader
