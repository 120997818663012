

const ObjectView = ({ obj, step = 1, theme = 'dark' }) => {

    const TAB_SIZE = 48

    const colors = {
        bg: { dark: 'black', light: 'white' },
        string: { dark: '#2FB782', light: 'green' },
        number: { dark: '#E0E219', light: '#E54A4A' },
        boolean: { dark: '#C92BDA', light: '#AA71C8' },
    }
    const open = '{'
    const close = '}'

    if (!obj) return <></>

    return (
        <div style={{ background: colors.bg[theme], width: 'fit-content', padding: '10px', borderRadius: '4px', maxHeight: '800px', overflowY: 'auto', color: '#6A85C3'  }} dir={'ltr'}>
            <span>{open}</span>
            {Object.keys(obj).map(key => {

                const value = obj[key]

                return (
                    <div style={{ marginLeft: `${step * TAB_SIZE}px`}}>
                        <span>{key}</span> :
                        {typeof value === 'string' &&
                            <span style={{ color: colors.string[theme], marginLeft: '4px' }}>"{value}"</span>
                        }
                        {typeof value === 'number' &&
                            <span style={{ color: colors.number[theme], marginLeft: '4px' }}>{value}</span>
                        }
                        {typeof value === 'boolean' &&
                            <span style={{ color: colors.boolean[theme], marginLeft: '4px' }}>{value ? 'true' : 'false'}</span>
                        }
                        { typeof value === 'object' &&
                            <ObjectView obj={value} step={step + 1} theme={theme} />
                        }
                    </div>
                )
            })}
            <span>{close}</span>
        </div>
    )
}

export default ObjectView
