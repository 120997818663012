import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import useFiles from "../../../../core/hooks/main/files/useFiles";
import RTableLayout from "../../layout/RTableLayout";
import {CFlex, CMargin, DText, Flex, IconWrapper, Row} from "../../../../styles/CommonStyles";
import {LineBreak, MiniLineBreak, RespIndex} from "../../../../styles/main/MainCommonStyles";
import {BarLoader} from "react-spinners";
import Tooltip from "../../../common/utils/Tooltip";
import {DownloadIcon} from "../../../../styles/main/setting";
import Text from "../../../../core/utils/Text";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../core/utils/theme";


const RFilesTable = ({
    data
}) => {

    const { data: files } = data
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        loading,
        selectedFile,
        onDownloadFile
    } = useFiles()

    return (
        <RTableLayout
            data={data}
        >
            {files?.data?.map((file, idx) => (
                <Row cs={'100%'} index={idx}>
                    <Flex fw justify={'flex-end'}>
                        {hasWriteAccess &&
                            ((loading && selectedFile?.name === file.name) ?
                                    <BarLoader
                                        color={'#ffc800'}
                                        width={'48px'}
                                    />
                                    :
                                    <Tooltip
                                        content={'download-file'}
                                    >
                                        <IconWrapper onClick={() => onDownloadFile(file)}>
                                            <DownloadIcon size={18}/>
                                        </IconWrapper>
                                    </Tooltip>
                            )

                        }
                    </Flex>
                    <CFlex fw>
                        <CMargin margin={'6px'} />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'file-name'} /> :
                            </DText>
                            <Flex justify={'flex-end'}>
                                <DText primary fontSize={'ss'} lineBreak={'anywhere'}>
                                    {file?.name}
                                </DText>
                            </Flex>
                        </Flex>
                        <LineBreak style={{ margin: '8px 0' }} />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'file-type'} /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(file.type)}>
                                <Text tid={file.type} />
                            </TradeTypeBadge>
                        </Flex>
                        <LineBreak style={{ margin: '8px 0' }} />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'file-status'} /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(file.status)}>
                                <Text tid={file.status} />
                            </TradeTypeBadge>
                        </Flex>
                    </CFlex>
                    <RespIndex>
                        {getTableIndex(idx)}
                    </RespIndex>
                </Row>
            ))}

        </RTableLayout>
    )
}


export default RFilesTable
