import { DropFilterBody, FilterBtn } from "../../../styles/layout/filter";
import { fadeVariants } from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import { DeleteIcon, Flex } from "../../../styles/CommonStyles";
import NameFilter from "./elements/NameFilter";
import { useRef } from "react";
import { useFilterContext } from "../../../core/contexts/filter";
import InputFilter from "./elements/InputFilter";
import SelectFilter from "./elements/SelectFilter";
import DateFilter from "./elements/DateFilter";
import CoinFilter from "./elements/CoinFilter";
import { useGetBlockchains } from "../../../core/services/react-query/setting/token";


const FilterBox = (props) => {
    const {
        type,
        filter,
        onClose
    } = props

    const ref = useRef()
    const { clearOneFilter } = useFilterContext()
    const { data: blockchains } = useGetBlockchains()


    const onSubmitClicked = () => {
        ref.current.submitFilters()
        // onClose()
    }

    const onDeleteFilterClicked = () => {
        clearOneFilter(filter)
        onClose()
    }

    const specialSearchNames = [
        'blockchain', 'role-name', 'file-name'
    ]

    const inputFilters = [
        'idNo', 'mobile',
        'email', 'affiliate-code',
        'smart-contract', 'token',
        'role-name', 'file-name',
        '_id', 'address', 'from-address',
        'to-address', 'txHash', 'fromId', 'toId'
    ]

    const selectFilters = [
        'verifyAt', 'trade-type',
        'transaction-type', 'transaction-flow',
        'transaction-status', 'file-status',
        'action', 'cause',
        'token-status', 'multi-type',
        'multi-status', 'file-type',
        'trace-type', 'status'
    ]

    return (
        <DropFilterBody
            variants={fadeVariants}
            animate='in'
            initial='out'
            exit='out'
            type={type}
        >
            {(filter.search === 'name' && !specialSearchNames.includes(filter.name)) &&
                <NameFilter
                    {...props}
                    ref={ref}
                />
            }

            {inputFilters.includes(filter.name) &&
                <InputFilter
                    {...props}
                    ref={ref}
                />
            }

            {selectFilters.includes(filter.name) &&
                <SelectFilter
                    {...props}
                    options={filter.options}
                    ref={ref}
                />
            }

            {filter.name === 'blockchain' &&
                <SelectFilter
                    {...props}
                    options={blockchains ? blockchains.map(item => item.name) : []}
                    ref={ref}
                />
            }

            {filter.name === 'network' &&
                <SelectFilter
                    {...props}
                    options={blockchains ? blockchains.map(item => item.networks[0]) : []}
                    ref={ref}
                />
            }

            {filter.search === 'date' &&
                <DateFilter
                    {...props}
                    ref={ref}
                />
            }

            {(filter.name === 'coin' || filter.name === 'pair') &&
                <CoinFilter
                    {...props}
                    ref={ref}
                />
            }

            <Flex style={{ marginTop: '16px' }}>
                <FilterBtn
                    onClick={onClose}
                >
                    <Text tid={'cancel'} />
                </FilterBtn>
                <FilterBtn
                    onClick={onSubmitClicked}
                    active
                >
                    <Text tid={'submit'} />
                </FilterBtn>
                {type === 'update' &&
                    <Flex
                        style={{ margin: '0 5px' }}
                        onClick={onDeleteFilterClicked}
                    >
                        <DeleteIcon size={20} />
                    </Flex>
                }
            </Flex>
        </DropFilterBody>
    )
}

export default FilterBox
